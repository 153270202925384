import { Box, Typography } from "@mui/material";
import SearchBar from "../SearchBar";

type StickyHeaderProps = {
  title: string;
  subtitle?: string;
};

const StickyHeader = ({ title, subtitle }: StickyHeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        backgroundColor: "background.paper",
        zIndex: 4,
        height: "56px",
        padding: "14px 14px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SearchBar />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Typography variant="h2">
          {title}
          {subtitle && (
            <>
              &nbsp;&gt;&nbsp;
              <span dangerouslySetInnerHTML={{ __html: subtitle }} />
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default StickyHeader;
