import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Story } from "../../types/storyInfo";
import StarFavourite from "../StarFavourite";
import CarouselCardContent from "./CarouselCardContent";

type CarouselStoryCardProps = {
  story: Story;
  isActive: boolean;
  onMouseEnter: () => void;
};

const CarouselStoryCard = ({
  story,
  isActive,
  onMouseEnter,
}: CarouselStoryCardProps) => {
  const navigate = useNavigate();

  return (
    <Box
      key={story.storyId}
      sx={{
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: isActive ? "30%" : "20%",
        height: "30vh",
        borderRadius: "8px",
        transition: "all 0.5s ease-in-out",
        opacity: isActive ? 1 : 0.5,
        zIndex: isActive ? 2 : 1,
        margin: "0 0.5%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "white",
        padding: "16px",
        cursor: "pointer",
        border: isActive ? "3px solid black" : "none",
        overflow: "hidden",
        transformOrigin: "center",
        backgroundImage: `url(${story.backgroundImage})`,
        transform: isActive ? "translateX(0)" : `translateX(0%)`,
        marginLeft: isActive ? "1px" : "0",
        marginRight: isActive ? "1px" : "0",
      }}
      onMouseEnter={onMouseEnter}
      onClick={() => navigate(`/story/${story.storyId}`)}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent 50%, rgba(0, 0, 0, 0.7))",
          pointerEvents: "none",
          zIndex: 1,
        }}
      />
      <CarouselCardContent story={story} />
      <StarFavourite />
    </Box>
  );
};

export default CarouselStoryCard;
