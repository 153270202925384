import { Box, IconButton, Tooltip } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import ToggleButtonIcon from "@mui/icons-material/ToggleOn";
import { useEffect, useRef, useState } from "react";

type ViewerControlsProps = {
  isPlaying: boolean;
  scriptOnlyMode: boolean;
  audioFiles: {
    music: string;
    soundscape: string;
  };
  setIsPlaying: (value: boolean) => void;
  onToggleScriptMode: () => void;
};

const ViewerControls = ({
  isPlaying,
  scriptOnlyMode,
  audioFiles,
  setIsPlaying,
  onToggleScriptMode,
}: ViewerControlsProps) => {
  const [isMuted, setIsMuted] = useState(false);

  const musicAudioRef = useRef<HTMLAudioElement | null>(null);
  const soundscapeAudioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioFiles.music && !musicAudioRef.current) {
      musicAudioRef.current = new Audio(audioFiles.music);
    }
    if (audioFiles.soundscape && !soundscapeAudioRef.current) {
      soundscapeAudioRef.current = new Audio(audioFiles.soundscape);
    }
  }, [audioFiles]);

  const play = () => {
    if (musicAudioRef.current) {
      musicAudioRef.current.play();
    }
    if (soundscapeAudioRef.current) {
      soundscapeAudioRef.current.play();
    }
    setIsPlaying(true);
  };

  const pause = () => {
    if (musicAudioRef.current) {
      musicAudioRef.current.pause();
    }
    if (soundscapeAudioRef.current) {
      soundscapeAudioRef.current.pause();
    }
    setIsPlaying(false);
  };

  const mute = () => {
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);

    if (musicAudioRef.current) {
      musicAudioRef.current.muted = newMutedState;
    }
    if (soundscapeAudioRef.current) {
      soundscapeAudioRef.current.muted = newMutedState;
    }
  };

  return (
    <Box
      sx={{
        width: "60%",
        height: "48px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 16px",
        marginTop: "16px",
        borderRadius: "4px",
        backgroundColor: "none",
      }}
    >
      <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
        {isPlaying ? (
          <IconButton
            onClick={pause}
            aria-label={"Pause scrolling"}
            disabled={scriptOnlyMode}
            sx={{
              color: "white",
              borderRadius: "8px",
              border: "1px solid white",
              width: "40px",
              height: "40px",
              backgroundColor: "#28D8A9",
            }}
          >
            <PauseIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={play}
            aria-label="Play scrolling"
            disabled={scriptOnlyMode}
            sx={{
              color: "white",
              borderRadius: "8px",
              border: "1px solid white",
              width: "40px",
              height: "40px",
              backgroundColor: "transparent",
            }}
          >
            <PlayArrowIcon />
          </IconButton>
        )}
        <IconButton
          onClick={mute}
          aria-label={isMuted ? "Unmute" : "Mute"}
          sx={{
            color: "white",
            borderRadius: "8px",
            border: "1px solid white",
            width: "40px",
            height: "40px",
          }}
        >
          {isMuted ? <VolumeOffIcon sx={{ color: "red" }} /> : <VolumeUpIcon />}
        </IconButton>

        <Tooltip
          title={
            scriptOnlyMode
              ? "Switch to Cinematic Mode"
              : "Switch to Script-Only Mode"
          }
        >
          <IconButton
            onClick={() => {
              onToggleScriptMode();
              scriptOnlyMode ? play() : pause();
            }}
            aria-label={
              scriptOnlyMode
                ? "Disable Script Only Mode"
                : "Enable Script Only Mode"
            }
            aria-pressed={scriptOnlyMode}
            sx={{
              color: "white",
              backgroundColor: scriptOnlyMode ? "#28D8A9" : "transparent",
              borderRadius: "12px",
              border: scriptOnlyMode ? "1px solid #28D8A9" : "1px solid white",
              width: "70px",
              height: "40px",
              transition: "background-color 0.3s, color 0.3s",
            }}
          >
            <ToggleButtonIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ViewerControls;
