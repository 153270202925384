import { Box, InputBase, alpha } from "@mui/material";

const SearchBar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={(theme) => ({
          position: "relative",
          borderRadius: "8px",
          backgroundColor: alpha(theme.palette.common.white, 0.05),
          "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.05),
          },
          marginRight: theme.spacing(2),
          width: "250px",
          height: "30px",
          border: "0.2px solid rgba(255, 255, 255, 0.2)",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.up("mobile")]: {
            marginLeft: theme.spacing(2),
            width: "auto",
          },
        })}
      >
        <InputBase
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          sx={(theme) => ({
            color: "inherit",
            "& .MuiInputBase-input": {
              padding: theme.spacing(0.5, 1, 0.5, 1),
              transition: theme.transitions.create("width"),
              width: "100%",
              [theme.breakpoints.up("laptop")]: {
                width: "23ch",
              },
              backgroundColor: "transparent",
              fontSize: "14px",
            },
          })}
        />
      </Box>
    </Box>
  );
};

export default SearchBar;
