import { useCallback } from "react";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";

const useFetchImage = (storyId: string | undefined) => {
  const { getToken } = useAuth();

  const fetchImage = useCallback(
    async (path: string) => {
      console.log("fetching image:", path);
      const token = await getToken();
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/get-comfy-image`,
          { image: path, storyId },
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          },
        );
        return response.data.url;
      } catch (error) {
        console.error(`Error fetching image at path ${path}:`, error);
        throw error;
      }
    },
    [getToken, storyId],
  );

  return { fetchImage };
};

export default useFetchImage;
