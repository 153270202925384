const adjectives = [
  "Fascinating",
  "Intriguing",
  "Captivating",
  "Thrilling",
  "Exciting",
  "Enchanting",
  "Remarkable",
  "Mysterious",
  "Compelling",
  "Breathtaking",
];

export function getRandomAdjective(): string {
  const randomIndex = Math.floor(Math.random() * adjectives.length);
  return adjectives[randomIndex];
}
