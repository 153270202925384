import React, { useState } from "react";
import axios from "axios";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import StoryForm from "../components/StoryForm";
import ProgressBar from "../components/story/ProgressBar";
import ScriptStage from "../components/story/stages/ScriptStage";
import StoryBeatsStage from "../components/story/stages/StoryBeatsStage";
import GenresStage from "../components/story/stages/GenresStage";
import TitleStage from "../components/story/stages/TitleStage";
import CharactersStage from "../components/story/stages/CharacterStage";
import ScenesStage from "../components/story/stages/ScenesStage";

type NewStoryStateProps = {
  currentStep: number;
  showStoryForm: boolean;
  subgraphs: string[];
  nextSubgraphIndex: number;
  storyId: string;
  showActionButton: boolean;
  editWindowContent: undefined | null | Record<string, string>;
};

type ResponseSubgraphType = {
  section: string;
  outputs: Record<string, string>;
};

const stageComponentMap: Record<string, React.ElementType> = {
  Title: TitleStage,
  Genre: GenresStage,
  Characters: CharactersStage,
  StoryBeats: StoryBeatsStage,
  Scenes: ScenesStage,
  Script: ScriptStage,
};

const NewStory = () => {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const [state, setState] = useState<NewStoryStateProps>({
    currentStep: -1,
    showStoryForm: true,
    subgraphs: ["Title"],
    nextSubgraphIndex: 1,
    storyId: "",
    showActionButton: false,
    editWindowContent: null,
  });
  const [allImagesAreLoaded, setAllImagesAreLoaded] = useState(false);

  const [characters, setCharacters] = useState();
  const [
    isCharacterBlueprintsPollingFinished,
    setIsCharacterBlueprintsPollingFinished,
  ] = useState(false);

  const updateState = (newState: Partial<NewStoryStateProps>) =>
    setState((prevState) => ({ ...prevState, ...newState }));

  const createStory = async (formData: { prompt: string }) => {
    try {
      const token = await getToken();
      updateState({ currentStep: 0, showStoryForm: false });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      const {
        subgraphs: subgraphData,
        storyId: newStoryId,
        title,
      } = response.data.message;

      updateState({
        subgraphs: [
          ...state.subgraphs,
          ...subgraphData.value.map(
            (subgraph: ResponseSubgraphType) => subgraph.section,
          ),
        ],
        storyId: newStoryId,
        editWindowContent: { Title: title.value },
        showActionButton: true,
      });
    } catch (error) {
      updateState({ showStoryForm: true });
      console.error("Error creating story:", error);
      alert(`Failed to create story: ${error}`);
    }
  };

  const processSubgraph = async () => {
    const { nextSubgraphIndex, subgraphs, storyId } = state;
    if (nextSubgraphIndex >= subgraphs.length) {
      updateState({ showActionButton: false });
      return;
    }

    const subgraph = subgraphs[nextSubgraphIndex];
    try {
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/run-subgraph`,
        { storyId, subgraph },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      const { output, status } = response.data.message;
      updateState({
        editWindowContent: output.value,
        nextSubgraphIndex:
          status.value === "complete"
            ? nextSubgraphIndex + 1
            : nextSubgraphIndex,
        showActionButton: status.value === "complete",
      });
    } catch (error) {
      console.error(`Error processing subgraph (${subgraph}):`, error);
      alert(`Failed to process subgraph (${subgraph}): ${error}`);
    }
  };

  const handleNext = async () => {
    updateState({
      showActionButton: false,
      editWindowContent: null,
      currentStep: state.currentStep + 1,
    });
    const token = await getToken();

    if (subgraphs[currentStep] === "Characters") {
      const startCharacterImageGeneration = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/start-character-blueprint-generation`,
            { storyId: state.storyId },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              withCredentials: true,
            },
          );
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      };

      await startCharacterImageGeneration();
    }

    if (subgraphs[currentStep] === "Scenes") {
      const completeImageGeneration = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/start-scenes-image-generation`,
            { storyId: state.storyId },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              withCredentials: true,
            },
          );
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      };

      await completeImageGeneration();
    }

    processSubgraph();
  };

  const {
    currentStep,
    showStoryForm,
    subgraphs,
    showActionButton,
    editWindowContent,
  } = state;

  const currentSubgraph = subgraphs[currentStep];

  const CurrentStageComponent = stageComponentMap[currentSubgraph];

  return (
    <Box sx={{ position: "relative", minHeight: "100vh", padding: "0px 30px" }}>
      <Box
        sx={{
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "30px",
          height: "100px",
        }}
      >
        {subgraphs.length > 1 && (
          <ProgressBar
            steps={subgraphs}
            currentStep={currentStep}
            stopCircularLoading={showActionButton}
          />
        )}
      </Box>

      {editWindowContent && CurrentStageComponent && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {React.createElement(CurrentStageComponent, {
            content: editWindowContent,
            ...(CurrentStageComponent === ScriptStage && {
              storyId: state.storyId,
              setAllImagesAreLoaded,
            }),
            ...(CurrentStageComponent === CharactersStage && {
              setCharacters,
            }),
            ...(CurrentStageComponent === ScenesStage && {
              characters,
              storyId: state.storyId,
              setIsCharacterBlueprintsPollingFinished,
              isCharacterBlueprintsPollingFinished,
            }),
          })}
        </Box>
      )}

      {!showStoryForm && !editWindowContent && currentStep === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ marginBottom: "20px" }}>
            Initial Setup Loading...
          </Typography>
          <CircularProgress size="70px" />
        </Box>
      )}

      {showStoryForm && (
        <Box sx={{ textAlign: "center" }}>
          <StoryForm handleSubmit={createStory} />
        </Box>
      )}
      {/* When Script show Save button instead ?? */}
      {showActionButton && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {(subgraphs[currentStep] !== "Scenes" ||
            isCharacterBlueprintsPollingFinished) && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (allImagesAreLoaded) {
                  navigate(`/story-viewer/${state.storyId}`);
                } else {
                  handleNext();
                }
              }}
            >
              {allImagesAreLoaded ? "Play" : "Next"}
            </Button>
          )}
        </Box>
      )}

      {showStoryForm && (
        <Box
          sx={{
            position: "absolute",
            bottom: 30,
            left: 0,
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography>How about...</Typography>
          <Typography fontStyle="italic" fontSize="15px">
            &quot;A night in The Woods&quot;
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NewStory;
