import { Box, Typography, Button } from "@mui/material";
import PreviewBox from "../components/PreviewBox";
import { defaultStories } from "../data/storiesData";
import PageLayout from "../components/layout/PageLayout";
import { useNavigate } from "react-router-dom";

const Library = () => {
  const navigate = useNavigate();

  return (
    <PageLayout title="Library">
      <Box sx={{ marginTop: "16px", marginLeft: "32px", marginRight: "32px" }}>
        <Typography
          variant="h5"
          sx={{ marginTop: "16px", marginBottom: "8px" }}
        >
          YOUR STORIES
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            gap: "16px",
          }}
        >
          {defaultStories.slice(0, 10).map((story) => (
            <PreviewBox
              key={story.storyId}
              storyId={story.storyId}
              title={story.title}
              genre={story.genre}
              username={story.username}
              backgroundImage={story.backgroundImage}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "2px solid #28D8A9",
            borderRadius: "12px",
            padding: "20px",
            marginTop: "32px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{ color: "#28D8A9", marginBottom: "16px" }}
          >
            CREATE YOUR OWN STORY!
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: "32px", color: "#D9D9D9" }}
          >
            Flex your creative muscles - have a go at creating your own dream
            story using our generative tools!
          </Typography>
          <Button
            variant="contained"
            sx={{
              background: "linear-gradient(90deg, #28D8A9 0%, #5FE692 100%)",
              color: "black",
              padding: "10px 20px",
              fontSize: "16px",
              border: "2px solid #5DF5AC",
              "&:hover": {
                background: "linear-gradient(90deg, #1E1A1E 0%, #44A669 100%)",
                color: "#5DF5AC",
              },
            }}
            onClick={() => navigate("/new-story")}
          >
            CREATE
          </Button>
        </Box>

        <Typography
          variant="h5"
          sx={{ marginTop: "32px", marginBottom: "8px" }}
        >
          SAVED STORIES
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            gap: "16px",
            marginBottom: "32px",
          }}
        >
          {defaultStories.map((story) => (
            <PreviewBox
              key={story.storyId}
              storyId={story.storyId}
              title={story.title}
              genre={story.genre}
              username={story.username}
              backgroundImage={story.backgroundImage}
            />
          ))}
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Library;
