import { IconButton } from "@mui/material";

interface EditButtonProps {
  disabled?: boolean;
  setDisabled?: (value: boolean) => void;
}

const EditButton = ({ disabled, setDisabled }: EditButtonProps) => (
  <IconButton
    sx={{
      position: "absolute",
      top: "50%",
      right: "8px",
      transform: "translateY(-50%)",
      color: disabled ? "#28D8A9" : "white",
    }}
    onClick={() => setDisabled && setDisabled(!disabled)}
  >
    <img src="/icons/edit-profile-icon.svg" alt="Edit" width={20} height={20} />
  </IconButton>
);

export default EditButton;
