import { useState, useRef, useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";

const MIN_HEIGHT = 200;
const MAX_HEIGHT = 500;

interface EditContentTabProps {
  children: React.ReactNode;
  titleText: string;
}

const EditContentTab = ({ children, titleText }: EditContentTabProps) => {
  const [height, setHeight] = useState<number>(300);
  const resizingRef = useRef<boolean>(false);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!resizingRef.current) return;
      setHeight((prevHeight) =>
        Math.min(MAX_HEIGHT, Math.max(MIN_HEIGHT, prevHeight + e.movementY)),
      );
    };

    const handleMouseUp = () => {
      resizingRef.current = false;
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    resizingRef.current = true;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <Typography sx={{ textAlign: "center", color: "#97BBDB" }}>
          Would you like to edit your story&apos;s {titleText}?
        </Typography>
        <Divider sx={{ margin: "16px 0" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "420px",
          height,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          overflow: "hidden",
          border: "1px solid rgba(255, 255, 255, 0.3)",
          paddingRight: "3px",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              borderRadius: "4px",
            },
          }}
        >
          {children}
        </Box>

        <Box
          sx={{
            height: "4px",
            width: "50px",
            borderRadius: "4px",
            cursor: "ns-resize",
            backgroundColor: "white",
            alignSelf: "center",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
          }}
          onMouseDown={handleMouseDown}
        />
      </Box>
    </Box>
  );
};

export default EditContentTab;
