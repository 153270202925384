import React from "react";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ScriptLine } from "../../viewer/StoryDisplay";

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import EditContentTab from "../EditContentTab";
import EditButton from "../../EditButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import theme from "../../../styles/theme";
import useFetchImage from "../../../hooks/useFetchImage";

type ScriptElement = { sceneId: string; scriptLines: ScriptLine[] };

interface ScriptStageProps {
  content: { Script: ScriptElement[] };
  storyId: string;
  setAllImagesAreLoaded: (value: boolean) => void;
}

const ScriptStage = ({
  content,
  storyId,
  setAllImagesAreLoaded,
}: ScriptStageProps) => {
  console.log("SCRIPT STAGE CONTENT:", { content });

  const { getToken } = useAuth();

  const { fetchImage } = useFetchImage(storyId);

  const [scenesImageData, setScenesImageData] = useState(() => {
    return content.Script.filter(
      (scene: ScriptElement) => !scene.hasOwnProperty("stageName"),
    ).map((scene: ScriptElement) => ({
      sceneId: scene.sceneId,
      images: {
        full_image_path: `images/generation_id${storyId}/scenes/scene_id${scene.sceneId}/full_image.webp`,
        full_image_url: "",
      },
    }));
  });

  const [musicUrl, setMusicUrl] = useState("");
  const [soundscapeUrl, setSoundscapeUrl] = useState("");

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const MAX_POLL_ATTEMPTS = 10;
  const pollCountRef = useRef(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateImageUrls = (sceneId: string, url: string) => {
    setScenesImageData((prevImageUrls) =>
      prevImageUrls.map((scene) =>
        scene.sceneId === sceneId
          ? {
              ...scene,
              images: {
                ...scene.images,
                full_image_url: url || "",
              },
            }
          : scene,
      ),
    );
  };

  const startMusicAndSoundscape = async () => {
    const token = await getToken();

    try {
      const [musicResponse, soundscapeResponse] = await Promise.all([
        axios.post(
          `${process.env.REACT_APP_API_URL}/generate-music`,
          { storyId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
        axios.post(
          `${process.env.REACT_APP_API_URL}/generate-soundscape`,
          { storyId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      ]);

      setMusicUrl(musicResponse.data.s3_url.url);
      setSoundscapeUrl(soundscapeResponse.data.s3_url.url);

      console.log("Music and soundscape started successfully.");
    } catch (error) {
      console.error("Error starting music or soundscape:", error);
    }
  };

  useEffect(() => {
    startMusicAndSoundscape();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scenesImageDataRef = useRef(scenesImageData);

  useEffect(() => {
    scenesImageDataRef.current = scenesImageData;
  }, [scenesImageData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchImages = () => {
    const currentScenesImageData = scenesImageDataRef.current;
    let hasUpdates = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentScenesImageData.forEach(async (scene: any) => {
      if (!scene.images.full_image_url) {
        const imgResult = await fetchImage(scene.images.full_image_path);
        updateImageUrls(scene.sceneId, imgResult);
        hasUpdates = true;
      }
    });

    if (hasUpdates) {
      setScenesImageData(currentScenesImageData);
    }
  };

  useEffect(() => {
    const areAllImagesLoaded = scenesImageData.every(
      (sceneImage) => sceneImage.images.full_image_url,
    );

    if (!areAllImagesLoaded && !intervalIdRef.current) {
      intervalIdRef.current = setInterval(() => {
        if (pollCountRef.current >= MAX_POLL_ATTEMPTS) {
          console.log("Reached maximum retry attempts. Stopping polling.");
          clearInterval(intervalIdRef.current!);
          intervalIdRef.current = null;
        } else {
          console.log(`Polling attempt ${pollCountRef.current + 1}`);
          fetchImages();
          pollCountRef.current += 1;
        }
      }, 60000);
    }

    if (areAllImagesLoaded) {
      setAllImagesAreLoaded(true);
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenesImageData, fetchImages, setAllImagesAreLoaded]);

  const audioMusicRef = React.useRef<HTMLAudioElement | null>(null);
  const audioSoundscapeRef = React.useRef<HTMLAudioElement | null>(null);

  const playMusicAudio = () => {
    audioMusicRef.current?.play();
  };

  const pauseMusicAudio = () => {
    audioMusicRef.current?.pause();
  };

  const playSoundscapeAudio = () => {
    audioSoundscapeRef.current?.play();
  };

  const pauseSoundscapeAudio = () => {
    audioSoundscapeRef.current?.pause();
  };

  return (
    <EditContentTab titleText="Script">
      <Box
        sx={{
          margin: "8px",
          padding: "16px",
          paddingTop: "0px",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 1,
          position: "relative",
          wordBreak: "break-word",
        }}
      >
        <Typography variant="subtitle1" sx={{ color: "white" }}>
          Audio
        </Typography>
        <Divider sx={{ marginBottom: "10px" }} />

        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              flex: 1,
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "rgba(255, 255, 255, 0.9)",
              }}
            >
              Music
            </Typography>
            {musicUrl ? (
              <>
                <audio ref={audioMusicRef}>
                  <source src={musicUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                <IconButton
                  onClick={playMusicAudio}
                  sx={{
                    width: "25px",
                    height: "25px",
                    backgroundColor: theme.palette.primary.main,
                    marginRight: "10px",
                  }}
                >
                  <PlayArrowIcon />
                </IconButton>
                <IconButton
                  onClick={pauseMusicAudio}
                  sx={{
                    width: "25px",
                    height: "25px",
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <PauseIcon />
                </IconButton>
              </>
            ) : (
              <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              flex: 1,
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: "rgba(255, 255, 255, 0.9)" }}
            >
              Soundscape
            </Typography>
            {soundscapeUrl ? (
              <>
                <audio ref={audioSoundscapeRef}>
                  <source src={soundscapeUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                <IconButton
                  onClick={playSoundscapeAudio}
                  sx={{
                    width: "25px",
                    height: "25px",
                    backgroundColor: theme.palette.primary.main,
                    marginRight: "10px",
                  }}
                >
                  <PlayArrowIcon />
                </IconButton>
                <IconButton
                  onClick={pauseSoundscapeAudio}
                  sx={{
                    width: "25px",
                    height: "25px",
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <PauseIcon />
                </IconButton>
              </>
            ) : (
              <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </Box>

        <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
          Script
        </Typography>
        <Divider
          sx={{
            marginBottom: "10px",
            borderColor: "rgba(255, 255, 255, 0.5)",
          }}
        />

        {content.Script.map((scene: ScriptElement, index: number) => {
          const foundImageUrls = scenesImageData.find(
            (scenesImageData) => scenesImageData.sceneId === scene.sceneId,
          );

          return (
            <Box key={index} sx={{ marginBottom: "20px" }}>
              {foundImageUrls ? (
                foundImageUrls.images.full_image_url ? (
                  <Box
                    sx={{
                      position: "relative",
                      width: 360,
                      margin: "10px auto",
                    }}
                  >
                    <img
                      src={foundImageUrls.images.full_image_url}
                      alt="Background"
                      style={{ width: "100%", display: "block" }}
                    />
                  </Box>
                ) : (
                  <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                    {pollCountRef.current >= MAX_POLL_ATTEMPTS ? (
                      <Typography>No Image Found ☹️</Typography>
                    ) : (
                      <CircularProgress size={20} />
                    )}{" "}
                  </Box>
                )
              ) : null}

              <Typography
                variant="h6"
                sx={{ color: "white", marginBottom: "5px" }}
              >
                Scene {scene.sceneId}
              </Typography>
              {scene.scriptLines.map((line, lineIndex) => (
                <Box key={lineIndex} sx={{ marginBottom: "10px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "rgba(255, 255, 255, 0.9)",
                      fontWeight: "bold",
                    }}
                  >
                    {line.characterName} ({line.lineType}):
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    {line.text}
                  </Typography>
                </Box>
              ))}
            </Box>
          );
        })}
        <Box sx={{ position: "absolute", top: "13px", right: "0px" }}>
          <EditButton />
        </Box>
      </Box>
    </EditContentTab>
  );
};
export default ScriptStage;
