import { useState } from "react";
import { Container, Divider, Box, Avatar, IconButton } from "@mui/material";
import CreditsBox from "../components/profile/CreditsBox";
import NonEditableField from "../components/profile/NonEditableField";
import EditableField from "../components/profile/EditableField";
import ProfileButtons from "../components/profile/ProfileButtons";

const Profile = () => {
  const [profileData, setProfileData] = useState({
    email: "user@gmail.com",
    password: "********",
    displayName: "User",
    profileDescription:
      "This is a profile description that will appear on the public page when people view your profile. Use this to describe your storytelling style or the type of stories you focus on.",
  });

  const [avatar, setAvatar] = useState<File | null>(null);

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setAvatar(event.target.files[0]);
    }
  };

  const updateProfileField =
    (field: keyof typeof profileData) => (value: string) => {
      setProfileData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    };

  return (
    <Box sx={{ width: "100%", height: "100vh", overflowY: "auto" }}>
      <Container maxWidth="tablet">
        <Box sx={{ padding: "24px", marginTop: "24px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "32px",
              position: "relative",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "none",
                width: 180,
                height: 180,
              }}
              src={
                avatar
                  ? URL.createObjectURL(avatar)
                  : "/icons/profile-image-icon.svg"
              }
            ></Avatar>
            <IconButton
              component="label"
              sx={{
                position: "absolute",
                top: "10px",
                right: "150px",
                left: "auto",
                color: "#28D8A9",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                },
              }}
            >
              <img
                src="/icons/edit-profile-icon.svg"
                alt="Edit"
                width={24}
                height={24}
              />
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleAvatarChange}
              />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <CreditsBox />
          </Box>

          <NonEditableField fieldValue={profileData.email} label="Email" />
          <NonEditableField
            fieldValue={profileData.password}
            label="Password"
          />

          <EditableField
            label="Display Name"
            fieldValue={profileData.displayName}
            updateField={updateProfileField("displayName")}
          />
          <EditableField
            label="Profile Description"
            fieldValue={profileData.profileDescription}
            updateField={updateProfileField("profileDescription")}
          />

          <Divider sx={{ margin: "16px 0" }} />

          <ProfileButtons />
        </Box>
      </Container>
    </Box>
  );
};

export default Profile;
