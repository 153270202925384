import PersonIcon from "@mui/icons-material/Person";
import ExploreIcon from "@mui/icons-material/Explore";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import CreateIcon from "@mui/icons-material/Create";
import LogoutIcon from "@mui/icons-material/Logout";

export interface NavItemProps {
  text: string;
  path: string;
  icon: React.ReactNode;
}

export const topNavItems: NavItemProps[] = [
  { text: "Explore", path: "/explore", icon: <ExploreIcon /> },
  { text: "Library", path: "/library", icon: <BookmarksIcon /> },
  { text: "New Story", path: "/new-story", icon: <CreateIcon /> },
];

export const bottomNavItems: NavItemProps[] = [
  {
    text: "Profile",
    path: "/profile",
    icon: <PersonIcon />,
  },
  {
    text: "Logout",
    path: "/",
    icon: <LogoutIcon />,
  },
];
