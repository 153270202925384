export const defaultStories = [
  {
    storyId: "10001",
    title: "Whispers of the Forgotten",
    synopsis: "Uncovering the history of...",
    bio: "Dive deep into a web of secrets as an unlikely hero unravels the mysteries hidden beneath the surface of a seemingly peaceful town. As the past collides with the present, danger lurks around every corner, and the truth may be more terrifying than fiction.",
    genre: "Thriller",
    username: "Some User",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
  {
    storyId: "10002",
    title: "The Lost Relic",
    synopsis: "A thrilling tale of adventure...",
    bio: "Join a daring adventurer on a pulse-pounding journey across treacherous landscapes, where the line between myth and reality blurs. With every step, ancient legends come to life, and the quest for a forgotten treasure tests the limits of bravery and resolve.",
    genre: "Adventure",
    username: "User2",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
  {
    storyId: "10003",
    title: "Starlight's Prophecy",
    synopsis: "An amazing journey through...",
    bio: "Embark on a fantastical journey where realms of magic and wonder stretch beyond the imagination. In a world where destiny is written in the stars, a young hero must confront the forces of darkness to restore balance and discover their true power.",
    genre: "Fantasy",
    username: "User3",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
  {
    storyId: "10004",
    title: "The Silent Enigma",
    synopsis: "Discover the secrets of...",
    bio: "In a town filled with whispered rumors and hidden truths, one detective must uncover the mysteries that lie beneath its tranquil facade. Each clue leads deeper into a labyrinth of deceit, where trust is a luxury and the stakes are deadly.",
    genre: "Mystery",
    username: "User4",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
  {
    storyId: "10005",
    title: "Echoes of Tomorrow",
    synopsis: "A story of mystery and...",
    bio: "In the not-so-distant future, a group of unlikely allies must navigate a world transformed by technology and secrets long buried. As they race against time to prevent a catastrophe, they discover that the future is shaped by the shadows of the past.",
    genre: "Sci-Fi",
    username: "User5",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
  {
    storyId: "10006",
    title: "Ties That Bind",
    synopsis: "An epic saga that...",
    bio: "A gripping tale of love, loss, and redemption, where the bonds of family are tested by time and tragedy. Through trials and tribulations, the characters must find their way back to each other before it's too late, in a drama that touches the heart.",
    genre: "Drama",
    username: "User6",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
  {
    storyId: "10007",
    title: "Rebellion's Rise",
    synopsis: "The adventure continues...",
    bio: "In a world on the brink of chaos, a reluctant hero must rise to the challenge and lead a rebellion against a tyrannical regime. The fight for freedom is fraught with danger, but courage and camaraderie might just tip the scales in this action-packed saga.",
    genre: "Action",
    username: "User7",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
  {
    storyId: "10008",
    title: "The Wild's Edge",
    synopsis: "A tale of survival in the wild...",
    bio: "Stranded in the harsh wilderness, a group of survivors must band together to endure the elements and each other. As they struggle for survival, their pasts come back to haunt them, revealing that the greatest threat may not be nature, but themselves.",
    genre: "Survival",
    username: "User8",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
  {
    storyId: "10009",
    title: "Voyagers of the Void",
    synopsis: "A journey through space...",
    bio: "In the vastness of space, a crew embarks on a daring mission to uncover the mysteries of a distant galaxy. As they venture further into the unknown, they encounter challenges that test the limits of human ingenuity and the bonds that hold them together.",
    genre: "Sci-Fi",
    username: "User9",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
  {
    storyId: "10010",
    title: "The Eternal Quest",
    synopsis: "An epic quest for the ultimate treasure...",
    bio: "Set in a world where legends and reality intertwine, a band of adventurers sets out on a perilous quest for the ultimate treasure. Along the way, they must confront their deepest fears and forge unbreakable bonds, or risk losing everything to the shadows of greed.",
    genre: "Fantasy",
    username: "User10",
    backgroundImage: "/images/example-gen-image.jpg",
    favourite: false,
  },
];
