import { Box, Typography } from "@mui/material";
import CarouselView from "../components/carousel/CarouselView";
import PreviewBox from "../components/PreviewBox";
import { defaultStories } from "../data/storiesData";
import PageLayout from "../components/layout/PageLayout";

const Explore = () => {
  return (
    <PageLayout title="Explore">
      <Box
        sx={{
          marginTop: "16px",
          paddingLeft: "32px",
          paddingRight: "32px",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <CarouselView stories={defaultStories.slice(0, 5)} />

        <Typography
          variant="h5"
          sx={{ marginTop: "16px", marginBottom: "8px" }}
        >
          FOR YOU
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          {defaultStories.slice(5, 9).map((story) => (
            <PreviewBox
              key={story.storyId}
              storyId={story.storyId}
              title={story.title}
              genre={story.genre}
              username={story.username}
              backgroundImage={story.backgroundImage}
            />
          ))}
        </Box>

        <Typography
          variant="h5"
          sx={{ marginTop: "24px", marginBottom: "8px" }}
        >
          POPULAR
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "8px",
            marginBottom: "32px",
          }}
        >
          {defaultStories.slice(0, 4).map((story) => (
            <PreviewBox
              key={story.storyId}
              storyId={story.storyId}
              title={story.title}
              genre={story.genre}
              username={story.username}
              backgroundImage={story.backgroundImage}
            />
          ))}
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Explore;
