import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000000", // Set to black as the base
      paper: "rgba(0, 0, 0, 0.3)", // Semi-transparent black for card backgrounds
    },
    primary: {
      main: "#28D8A9", // Green color from the design sheet
      light: "#5FE692", // Lighter green for hover states
    },
    text: {
      primary: "#FFFFFF", // White for main text
      secondary: "#D9D9D9", // Light gray for secondary text
    },
  },
  typography: {
    fontFamily: '"Alata", "Arial", sans-serif',
    h1: {
      fontSize: 38,
    },
    h2: {
      fontSize: 24,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            "linear-gradient(59deg, #666666 0%, #292828 0%, #282D0C 54%, #290909 60%, #000000 83%)",
          backgroundAttachment: "fixed",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "#28D8A9",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#5FE692",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#28D8A9",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#28D8A9",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#28D8A9",
            },
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
});

export default theme;
