import { Box, Divider, Typography } from "@mui/material";
import EditContentTab from "../EditContentTab";
import EditButton from "../../EditButton";
import { useEffect } from "react";

interface CharactersStageProps {
  content: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Characters: any[];
  };
  setCharacters: (characters: string[]) => void;
}

const CharactersStage = ({ content, setCharacters }: CharactersStageProps) => {
  console.log("Character's stage content:", content);

  useEffect(() => {
    const charactersScriptNames = content.Characters.map((character) =>
      character.scriptName.replace(/\s+/g, ""),
    );

    setCharacters(charactersScriptNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <EditContentTab titleText="Characters">
      {content.Characters.map((character, index) => (
        <Box
          key={index}
          sx={{
            margin: "8px",
            padding: "16px",
            paddingTop: "0px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderRadius: 1,
            position: "relative",
            wordBreak: "break-word",
          }}
        >
          <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
            Characters: {character.fullName}
          </Typography>
          <Divider
            sx={{
              marginBottom: "10px",
              borderColor: "rgba(255, 255, 255, 0.5)",
            }}
          />

          <Box sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
            <Typography variant="body2">
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Backstory:
              </span>{" "}
              {character.backstory}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Character Role:
              </span>{" "}
              {character.characterRole}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Full Name:
              </span>{" "}
              {character.fullName}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Narrative Character Tropes:
              </span>{" "}
              {character.narrativeCharacterTropes}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Personal Drive:
              </span>{" "}
              {character.personalDrive}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Personality Traits:
              </span>{" "}
              {character.personalityTraits}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Plot Relevance:
              </span>{" "}
              {character.plotRelevance}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Script Name:
              </span>{" "}
              {character.scriptName}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Setting Character Tropes:
              </span>{" "}
              {character.settingCharacterTropes}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Spoken Style:
              </span>{" "}
              {character.spokenStyle}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Story Beat Reference:
              </span>{" "}
              {character.storyBeatReference}
              <br />
              <span
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Visual Description:
              </span>{" "}
              {character.visualDescription}
              <br />
            </Typography>

            <Box sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
              <Typography variant="body2">
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.9)",
                    fontWeight: "bold",
                  }}
                >
                  Character Journey:
                </span>
              </Typography>

              <ul style={{ fontSize: "14px" }}>
                <li>
                  <span
                    style={{
                      color: "rgba(255, 255, 255, 0.9)",
                      fontWeight: "bold",
                    }}
                  >
                    Starting Stage:
                  </span>{" "}
                  {character.characterJourney?.startingStage}
                </li>
                <li>
                  <span
                    style={{
                      color: "rgba(255, 255, 255, 0.9)",
                      fontWeight: "bold",
                    }}
                  >
                    Transformation:
                  </span>{" "}
                  {character.characterJourney?.transformation}
                </li>
                <li>
                  <span
                    style={{
                      color: "rgba(255, 255, 255, 0.9)",
                      fontWeight: "bold",
                    }}
                  >
                    End Stage:
                  </span>{" "}
                  {character.characterJourney?.endStage}
                </li>
                <li>
                  <span
                    style={{
                      color: "rgba(255, 255, 255, 0.9)",
                      fontWeight: "bold",
                    }}
                  >
                    Journey Themes:
                  </span>{" "}
                  {character.characterJourney?.journeyThemes.join(", ")}
                </li>
              </ul>
            </Box>

            <Box sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
              <Typography
                variant="body2"
                sx={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.9)",
                    fontWeight: "bold",
                  }}
                >
                  Character Relations:
                </span>{" "}
              </Typography>
            </Box>

            <ul style={{ fontSize: "14px" }}>
              {character.characterRelations?.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (relation: any, index: number) => (
                  <li key={index}>
                    <span
                      style={{
                        color: "rgba(255, 255, 255, 0.9)",
                        fontWeight: "bold",
                      }}
                    >
                      {relation.characterName}:
                    </span>{" "}
                    (Relationship Types: {relation.relationshipTypes.join(", ")}
                    , Opinion Score: {relation.opinionScore})
                  </li>
                ),
              )}
            </ul>
          </Box>

          <Box sx={{ position: "absolute", top: "13px", right: "0px" }}>
            <EditButton />
          </Box>
        </Box>
      ))}
    </EditContentTab>
  );
};

export default CharactersStage;
