import { Box, Typography, Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { Story } from "../../types/storyInfo";

type CarouselCardContentProps = {
  story: Story;
};

const CarouselCardContent = ({ story }: CarouselCardContentProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "100%",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          position: "absolute",
          top: "16px",
          left: "16px",
          textShadow: "2px 2px 4px black",
        }}
      >
        &quot;{story.title}&quot;
      </Typography>
      <Typography variant="body2">{story.synopsis}</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "8px",
          cursor: "pointer",
          zIndex: 3,
        }}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/profile/${story.username}`);
        }}
      >
        <Avatar
          sx={{
            bgcolor: "white",
            color: "black",
            width: "24px",
            height: "24px",
            marginRight: "8px",
          }}
        >
          <PersonIcon sx={{ fontSize: 16 }} />
        </Avatar>
        <Typography
          variant="subtitle2"
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              color: "primary.main",
            },
          }}
        >
          A {story.genre} by {story.username}
        </Typography>
      </Box>
    </Box>
  );
};

export default CarouselCardContent;
