import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Box,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavItems from "./NavItems";

const drawerWidth = 240;

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDrawer = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Drawer
        variant="permanent"
        sx={{
          width: isExpanded ? drawerWidth : (theme) => theme.spacing(7),
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isExpanded ? drawerWidth : (theme) => theme.spacing(7),
            transition: (theme) =>
              theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: "14px",
          },
        }}
      >
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 52,
                justifyContent: isExpanded ? "initial" : "center",
                px: 2.5,
                marginTop: "-5px",
              }}
              onClick={toggleDrawer}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  marginRight: isExpanded ? "24px" : "auto",
                  justifyContent: "center",
                }}
              >
                <MenuIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <NavItems isExpanded={isExpanded} />
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: "24px", margin: "0px", padding: "0px" }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Navbar;
