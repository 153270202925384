import { Navigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";

const RedirectToSignIn = () => {
  const { isLoaded, userId } = useAuth();

  if (!isLoaded) {
    return null;
  }

  if (userId) {
    return null;
  }

  return <Navigate to="/" replace />;
};

export default RedirectToSignIn;
