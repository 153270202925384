import { useRef, useEffect, useCallback, useMemo, useState } from "react";

import { Box } from "@mui/material";
import SubtitleDisplay from "./SubtitleDisplay";
import useFetchImage from "../../hooks/useFetchImage";

const amplitude = {
  background: 10,
  storyboardFrame: 16,
  character: 20,
  foreground: 12,
} as const;

type AmplitudeKeys = keyof typeof amplitude;

export interface ScriptLine {
  lineType: "Action" | "Dialogue" | "ToneChange" | "SceneChange";
  characterName: string;
  text: string;
  displayTime: number;
}

export type ExtendedScriptLine = ScriptLine & {
  sceneId: string;
};

type StoryDisplayProps = {
  scriptOnlyMode: boolean;
  textBoxHeight: number;
  textContent: ExtendedScriptLine[];
  isPlaying: boolean;
  storyId: string;
  initialImageUrls: {
    character: string;
    background: string;
    foreground: string;
  };
};

const StoryDisplay = ({
  scriptOnlyMode,
  textBoxHeight,
  textContent,
  isPlaying,
  storyId,
  initialImageUrls,
}: StoryDisplayProps) => {
  const { fetchImage } = useFetchImage(storyId);

  const [isTransitioning, setIsTransitioning] = useState(false);
  const [imageUrls, setImageUrls] = useState({
    characterUrl: "",
    backgroundUrl: "",
    foregroundUrl: "",
  });

  const aspectRatio = 16 / 9;
  const canvasHeight = `calc(100vh - ${textBoxHeight}px - 120px)`;
  const canvasWidth = `calc(${canvasHeight} * ${aspectRatio})`;
  const canvasMaxWidth = "calc(100% - 64px)";
  const backgroundBlur = 3;
  const foregroundBlur = 1;

  useEffect(() => {
    setImageUrls({
      characterUrl: initialImageUrls.character,
      backgroundUrl: initialImageUrls.background,
      foregroundUrl: initialImageUrls.foreground,
    });
  }, [
    initialImageUrls.character,
    initialImageUrls.background,
    initialImageUrls.foreground,
  ]);

  const refs = {
    background: useRef<HTMLDivElement>(null),
    storyboardFrame: useRef<HTMLDivElement>(null),
    character: useRef<HTMLDivElement>(null),
    foreground: useRef<HTMLDivElement>(null),
    nestedCanvas: useRef<HTMLDivElement>(null),
  };

  const parallaxFactors = useMemo(
    () => ({
      background: 0.08,
      storyboardFrame: 0.16,
      character: 0.32,
      foreground: 0.2,
    }),
    [],
  );

  const animateParallax = useCallback(() => {
    const timestamp = Date.now();

    const offsets = {
      background: {
        x: Math.sin(timestamp / 3000),
        y: Math.cos(timestamp / 4000),
      },
      storyboardFrame: {
        x: Math.sin(timestamp / 3600 + 0.5),
        y: Math.cos(timestamp / 5000 + 0.5),
      },
      character: {
        x: Math.sin(timestamp / 4000 + 1),
        y: Math.cos(timestamp / 6000 + 1),
      },
      foreground: {
        x: Math.sin(timestamp / 4400 + 1.5),
        y: Math.cos(timestamp / 6400 + 1.5),
      },
    };

    Object.keys(refs).forEach((key) => {
      const ref = refs[key as keyof typeof refs].current;
      if (ref && key !== "nestedCanvas") {
        const { x, y } = offsets[key as keyof typeof offsets];
        const factor = parallaxFactors[key as keyof typeof parallaxFactors];
        const scale =
          key === "character"
            ? 0.9 + 0.01 * Math.sin((timestamp / 10000) * 2 * Math.PI)
            : 0.95;

        const amplitudeValue = amplitude[key as AmplitudeKeys];
        ref.style.transform = `translate(-50%, -50%) translate(${x * factor * amplitudeValue}px, ${y * factor * amplitudeValue}px) scale(${scale})`;
      }
    });

    requestAnimationFrame(animateParallax);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parallaxFactors]);

  useEffect(() => {
    if (isPlaying) {
      const animationFrameId = requestAnimationFrame(animateParallax);
      return () => cancelAnimationFrame(animationFrameId);
    }
  }, [isPlaying, animateParallax]);

  useEffect(() => {
    const { current: nestedCanvas } = refs.nestedCanvas;

    if (!nestedCanvas) return;

    if (isTransitioning) {
      nestedCanvas.classList.add("transition");
      nestedCanvas.addEventListener("animationend", () => {
        nestedCanvas.classList.remove("transition");
        setIsTransitioning(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransitioning, setIsTransitioning]);

  const fetchImagesForScene = async (sceneId: string) => {
    if (sceneId === "1.1") {
      console.log("sceneId 1.1", sceneId);
    }

    if (sceneId !== "1.1") {
      const characterImgPath = `images/generation_id${storyId}/scenes/scene_id${sceneId}/character_segmented.webp`;
      const backgroundImgPath = `images/generation_id${storyId}/scenes/scene_id${sceneId}/background.webp`;
      const foregroundImgPath = `images/generation_id${storyId}/scenes/scene_id${sceneId}/foreground.webp`;

      const [characterUrl, backgroundUrl, foregroundUrl] = await Promise.all([
        fetchImage(characterImgPath),
        fetchImage(backgroundImgPath),
        fetchImage(foregroundImgPath),
      ]);

      setImageUrls({ characterUrl, backgroundUrl, foregroundUrl });
    }
  };

  return (
    <>
      <Box
        sx={{
          width: `min(${canvasWidth}, ${canvasMaxWidth})`,
          height: `calc(${canvasWidth} / ${aspectRatio})`,
          backgroundColor: "none",
          margin: "16px auto",
          transition: "height 0.3s, width 0.3s, opacity 0.3s ease-in-out",
          opacity: scriptOnlyMode ? 0 : 1,
          perspective: "2000px",
        }}
      >
        {imageUrls.characterUrl &&
          imageUrls.backgroundUrl &&
          imageUrls.foregroundUrl && (
            <Box
              ref={refs.nestedCanvas}
              className="nested-canvas"
              sx={{ height: "100%" }}
            >
              {["background", "character", "foreground"].map((layer) => (
                <Box
                  key={layer}
                  ref={refs[layer as keyof typeof refs]}
                  component="img"
                  src={imageUrls[`${layer}Url` as keyof typeof imageUrls]}
                  alt={layer}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    filter:
                      layer === "background"
                        ? `blur(${backgroundBlur}px)`
                        : layer === "foreground"
                          ? `blur(${foregroundBlur}px) drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.7))`
                          : "", // No blur for character
                    transform: `translate(-50%, -50%) scale(${layer === "foreground" ? 0.95 : layer === "background" ? 0.8 : 0.9})`,
                    zIndex:
                      layer === "foreground"
                        ? 3
                        : layer === "character"
                          ? 2
                          : 0,
                  }}
                />
              ))}

              <Box
                ref={refs.storyboardFrame}
                component="img"
                src="/images/storyboard-frame.png"
                alt="Storyboard Frame"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transform: "translate(-50%, -50%) scale(0.75)",
                  zIndex: 0.5,
                }}
              />
            </Box>
          )}

        <SubtitleDisplay
          textContent={textContent}
          isPlaying={isPlaying}
          onSceneChange={(sceneId) => {
            console.log("SCENECHANGE");
            setIsTransitioning(true);

            if (!sceneId) {
              console.log("NO IMAGES");
              return;
            }
            fetchImagesForScene(sceneId);
          }}
        />
      </Box>

      <style>
        {`
         @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
        `}
      </style>
    </>
  );
};

export default StoryDisplay;
