import { useState } from "react";
import { Box } from "@mui/material";
import CarouselStoryCard from "./CarouselStoryCard";
import { Story } from "../../types/storyInfo";

type CarouselViewProps = {
  stories: Story[];
};

const CarouselView = ({ stories }: CarouselViewProps) => {
  const [currentIndex, setCurrentIndex] = useState(2);

  const handleMouseEnter = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "100%",
        overflow: "hidden",
        padding: "20px 0",
      }}
    >
      {stories.map((story, index) => (
        <CarouselStoryCard
          key={story.storyId}
          story={story}
          isActive={index === currentIndex}
          onMouseEnter={() => handleMouseEnter(index)}
        />
      ))}
    </Box>
  );
};

export default CarouselView;
