import { Box } from "@mui/material";

type ConnectorProps = {
  index: number;
  currentStep: number;
};

const Connector = ({ index, currentStep }: ConnectorProps) => (
  <Box sx={{ display: "flex", marginTop: "-60px" }}>
    {[0, 1].map((_, i) => (
      <Box
        key={i}
        sx={(theme) => ({
          width: "15px",
          height: "1px",
          backgroundColor:
            index < currentStep
              ? theme.palette.primary.main
              : "rgba(255, 255, 255, 0.5)",
          mx: "5px",
          transition: `background-color ${0.7 + i * 0.3}s ease-in-out`,
        })}
      />
    ))}
  </Box>
);

export default Connector;
