import { Box, Typography } from "@mui/material";
import StoryFormat from "./StoryFormat";
import { ExtendedScriptLine } from "./StoryDisplay";

type ScrollTextBoxProps = {
  textBoxHeight: number;
  scriptOnlyMode: boolean;
  textContent: ExtendedScriptLine[];
};

const ScrollTextBox = ({
  textBoxHeight,
  scriptOnlyMode,
  textContent,
}: ScrollTextBoxProps) => {
  const renderContent = () => {
    if (textContent && textContent.length > 0) {
      return textContent.map((line, index) => (
        <StoryFormat key={index} line={line} index={index} />
      ));
    }
    return <Typography>Loading script...</Typography>;
  };

  return (
    <Box
      sx={{
        height: `${textBoxHeight}px`,
        display: "flex",
        width: "60%",
        backgroundColor: scriptOnlyMode ? "#1e1e1e" : "transparent",
        position: "relative",
        transition: "height 0.3s ease-in-out",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: scriptOnlyMode ? "inline-block" : "none",
          overflowY: "auto",
          maskImage:
            "linear-gradient(to bottom, transparent, white 10%, white 90%, transparent)",
          WebkitMaskImage:
            "linear-gradient(to bottom, transparent, white 10%, white 90%, transparent)",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#1e1e1e",
          },
        }}
      >
        {renderContent()}
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "6px",
          position: "absolute",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "calc(50% - 32px)",
            height: "3px",
            backgroundColor: "white",
            borderRadius: "4px",
          }}
        />
        <img
          src="/logos/nib-icon.png"
          alt="Nib Icon"
          style={{
            width: "48px",
            height: "48px",
            margin: "0 16px",
            transition: "transform 0.3s ease-in-out",
            transform: scriptOnlyMode ? "rotate(90deg)" : "none",
          }}
        />
        <Box
          sx={{
            width: "calc(50% - 32px)",
            height: "3px",
            backgroundColor: "white",
            borderRadius: "4px",
          }}
        />
      </Box>
    </Box>
  );
};

export default ScrollTextBox;
