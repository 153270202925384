import { TextField, Button, Typography, Box, Divider } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";

type StoryFormProps = {
  handleSubmit: (formData: { prompt: string }) => void;
};

const StoryForm = ({ handleSubmit }: StoryFormProps) => {
  const [formData, setFormData] = useState({
    prompt: "",
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    handleSubmit(formData);
  };

  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <Box component="form" onSubmit={onSubmit} sx={{ minWidth: "380px" }}>
        <Typography sx={{ textAlign: "center", color: "#97BBDB" }}>
          What&apos;s your dream story?
        </Typography>

        <Divider sx={{ margin: "16px 0" }} />

        <TextField
          variant="outlined"
          placeholder="Enter a story title or description"
          fullWidth
          name="prompt"
          value={formData.prompt}
          onChange={handleChange}
          required
          margin="normal"
        />
        <Button
          disabled={!formData.prompt}
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            borderRadius: "20px",
            height: "30px",
            color: "black",
            width: "90px",
            alignSelf: "center",
            marginTop: "10px",
          }}
        >
          Generate
        </Button>
      </Box>
    </Box>
  );
};

export default StoryForm;
