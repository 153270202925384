import { Box, Divider, Typography } from "@mui/material";
import EditContentTab from "../EditContentTab";
import EditButton from "../../EditButton";

interface TitleStageProps {
  content: Record<string, string>;
}

const TitleStage = ({ content }: TitleStageProps) => {
  const contentKey = Object.keys(content)[0];
  const contentValue = content[contentKey];

  console.log({ content });
  return (
    <EditContentTab titleText={contentKey}>
      <Box
        sx={{
          margin: "8px",
          padding: "16px",
          paddingTop: "0px",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 1,
          position: "relative",
          wordBreak: "break-word",
        }}
      >
        <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
          {contentKey}
        </Typography>
        <Divider
          sx={{
            marginBottom: "10px",
            borderColor: "rgba(255, 255, 255, 0.5)",
          }}
        />

        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          {contentValue}
        </Typography>
        <Box sx={{ position: "absolute", top: "13px", right: "0px" }}>
          <EditButton />
        </Box>
      </Box>
    </EditContentTab>
  );
};

export default TitleStage;
