import { IconButton } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { useState } from "react";

const StarFavourite = () => {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <IconButton
      onClick={(event) => {
        event.stopPropagation();
        setIsSelected(true);
      }}
      sx={{
        color: isSelected ? "yellow" : "white",
        position: "absolute",
        top: "16px",
        right: "16px",
        zIndex: 2,
      }}
    >
      {isSelected ? (
        <StarIcon
          sx={{
            stroke: "black",
            strokeWidth: 2,
          }}
        />
      ) : (
        <StarBorderIcon />
      )}
    </IconButton>
  );
};

export default StarFavourite;
