import { Typography, TextField, Box } from "@mui/material";
import { useState } from "react";
import EditButton from "../EditButton";

type EditableFieldProps = {
  label: string;
  fieldValue: string;
  updateField: (value: string) => void;
};

const EditableField = ({
  label,
  fieldValue,
  updateField,
}: EditableFieldProps) => {
  const [disabled, setDisabled] = useState(true);

  return (
    <Box
      sx={{
        position: "relative",
        marginBottom: "24px",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginBottom: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {label}
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        value={fieldValue}
        disabled={disabled}
        multiline={label === "Profile Description" && true}
        rows={4}
        onChange={(e) => updateField(e.target.value)}
        sx={{
          textAlign: "center",
          bgcolor: "rgba(255, 255, 255, 0.05)",
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            "&.Mui-disabled": {
              "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.2)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(255, 255, 255, 0.2)",
              },
              color: "white",
            },
            input: {
              textAlign: "center",
            },
          },
          input: {
            padding: "12px 14px",
          },
        }}
      />
      <EditButton disabled={disabled} setDisabled={setDisabled} />
    </Box>
  );
};

export default EditableField;
