import { List, Box } from "@mui/material";
import NavItem from "./NavItem";
import { bottomNavItems, topNavItems } from "../../utils/navItems";

type NavItemsProps = {
  isExpanded: boolean;
};

const NavItems = ({ isExpanded }: NavItemsProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <List sx={{ marginTop: "-15px", marginBottom: "auto" }}>
        {topNavItems.map((item) => (
          <NavItem key={item.text} item={item} isExpanded={isExpanded} />
        ))}
      </List>
      <List sx={{ marginTop: "auto", marginBottom: "0px" }}>
        {bottomNavItems.map((item) => (
          <NavItem key={item.text} item={item} isExpanded={isExpanded} />
        ))}
      </List>
    </Box>
  );
};

export default NavItems;
