import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import StoryDisplay, { ScriptLine } from "../components/viewer/StoryDisplay";
import ViewerControls from "../components/viewer/ViewerControls";
import ScrollTextBox from "../components/viewer/ScrollTextBox";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import AlertMessage from "../components/AlertMessage";
import useFetchImage from "../hooks/useFetchImage";

type SceneType = {
  sceneId: string;
  scriptLines: ScriptLine[];
};

const StoryViewer = () => {
  const minHeight = 25;

  const [scriptOnlyMode, setScriptOnlyMode] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [textBoxHeight, setTextBoxHeight] = useState(minHeight);
  const [alert, setAlert] = useState("");

  const [imageUrls, setImageUrls] = useState({
    character: "",
    background: "",
    foreground: "",
  });

  const [audioFiles, setAudioFiles] = useState({
    music: "",
    soundscape: "",
  });

  const { getToken } = useAuth();
  const { storyId } = useParams<{ storyId: string }>();
  const [story, setStory] = useState();

  const { fetchImage } = useFetchImage(storyId);

  const fetchStory = async (storyId: string) => {
    const token = await getToken();

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/get-story-script-lines/${storyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );

    // Parse and filter data, then create a flat array of script lines all having scene ids
    const formattedData = data.flatMap((scene: SceneType) =>
      scene.scriptLines.map((line: ScriptLine) => ({
        ...line,
        sceneId: scene.sceneId,
      })),
    );

    setStory(formattedData);
  };

  const fetchInitialImages = async () => {
    const paths = {
      characterImgPath: `images/generation_id${storyId}/scenes/scene_id1.1/character_segmented.webp`,
      backgroundImgPath: `images/generation_id${storyId}/scenes/scene_id1.1/background.webp`,
      foregroundImgPath: `images/generation_id${storyId}/scenes/scene_id1.1/foreground.webp`,
    };

    try {
      const urls = await Promise.all(
        Object.values(paths).map((path) => fetchImage(path)),
      );

      setImageUrls({
        character: urls[0],
        background: urls[1],
        foreground: urls[2],
      });
    } catch (error) {
      console.error(`Error fetching images for scene 1.1`, error);
    }
  };

  const fetchAudio = async () => {
    const token = await getToken();
    const musicPath = `generation_id_${storyId}/music.mp3`;
    const soundscapePath = `generation_id_${storyId}/soundscape.mp3`;

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/get-audio`,
      {
        storyId,
        audioPaths: { soundscape_path: soundscapePath, music_path: musicPath },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );

    let alertsMsg = "";
    const { soundscape, music } = response.data.urls;

    if (!music) {
      alertsMsg += "No Music found! ";
    }
    if (!soundscape) {
      alertsMsg += "No Soundscape found! ";
    }
    if (alertsMsg) {
      setAlert(alertsMsg.trim());
    }

    setAudioFiles({
      soundscape: soundscape || "",
      music: music || "",
    });
    return response.data.url;
  };

  useEffect(() => {
    if (storyId) {
      fetchStory(storyId);
      fetchInitialImages();
      fetchAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);

  const handleScriptOnlyModeToggle = () => {
    setScriptOnlyMode((prev) => {
      const newMode = !prev;
      setTextBoxHeight(newMode ? window.innerHeight * 0.85 : minHeight);
      setIsPlaying(false);
      return newMode;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#121212",
        padding: "16px",
      }}
    >
      {alert && <AlertMessage severity="error" message={alert} />}
      {storyId && story ? (
        <>
          <StoryDisplay
            scriptOnlyMode={scriptOnlyMode}
            textBoxHeight={textBoxHeight}
            textContent={story}
            isPlaying={isPlaying}
            storyId={storyId}
            initialImageUrls={imageUrls}
          />
          <ScrollTextBox
            textBoxHeight={textBoxHeight}
            scriptOnlyMode={scriptOnlyMode}
            textContent={story}
          />
          <ViewerControls
            isPlaying={isPlaying}
            scriptOnlyMode={
              scriptOnlyMode ||
              !imageUrls.background ||
              !imageUrls.character ||
              !imageUrls.foreground
            }
            audioFiles={audioFiles}
            setIsPlaying={setIsPlaying}
            onToggleScriptMode={handleScriptOnlyModeToggle}
          />
        </>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default StoryViewer;
