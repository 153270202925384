import { Box, Typography, Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import StarFavourite from "./StarFavourite";

type PreviewBoxProps = {
  storyId: string;
  title: string;
  genre: string;
  username: string;
  backgroundImage: string;
};

const PreviewBox = ({
  storyId,
  title,
  genre,
  username,
  backgroundImage,
}: PreviewBoxProps) => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => navigate(`/story/${storyId}`)}
      sx={{
        position: "relative",
        width: "calc(25% - 16px)",
        height: "180px",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "top center",
        borderRadius: "8px",
        overflow: "hidden",
        marginTop: "16px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        color: "white",
        cursor: "pointer",
        "::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(to right, black, transparent)",
          borderRadius: "8px",
          border: "2px solid black",
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="h5" gutterBottom>
          &quot;{title}&quot;
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            zIndex: 3,
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/profile/${username}`);
          }}
        >
          <Avatar
            sx={{
              bgcolor: "white",
              color: "black",
              marginRight: "8px",
              width: "24px",
              height: "24px",
            }}
          >
            <PersonIcon sx={{ fontSize: 16 }} />
          </Avatar>
          <Typography
            variant="subtitle2"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
            A {genre} by {username}
          </Typography>
        </Box>
      </Box>
      <StarFavourite />
    </Box>
  );
};

export default PreviewBox;
