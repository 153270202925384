import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TermsAndPrivacyLinks = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "16px",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography variant="body2">
        <Link
          onClick={() => navigate("/terms")}
          sx={{
            textTransform: "none",
            textDecoration: "none",
            "&:hover": {
              backgroundColor: "transparent",
              color: (theme) => theme.palette.primary.light,
              textDecoration: "underline",
            },
            "&:focus": {
              outline: "none",
            },
          }}
        >
          Terms of use
        </Link>
        {" | "}
        <Link
          onClick={() => navigate("/privacy-policy")}
          sx={{
            textTransform: "none",
            textDecoration: "none",
            "&:hover": {
              backgroundColor: "transparent",
              color: (theme) => theme.palette.primary.light,
              textDecoration: "underline",
            },
            "&:focus": {
              outline: "none",
            },
          }}
        >
          Privacy policy
        </Link>
      </Typography>
    </Box>
  );
};

export default TermsAndPrivacyLinks;
