import { Button } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { useState } from "react";

const SaveButton = () => {
  const [isFavourite, setIsFavourite] = useState(false);

  const handleFavouriteToggle = () => {
    setIsFavourite((prev) => !prev);
  };

  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: isFavourite ? "#28D8A9" : "#28D8A9",
        color: "black",
        "&:hover": {
          bgcolor: isFavourite ? "#FF5252" : "#5FE692",
          color: "white",
          "& .MuiButton-endIcon": {
            color: "white",
          },
        },
        "& .MuiButton-endIcon": {
          color: isFavourite ? "yellow" : "black",
        },
        display: "flex",
        justifyContent: "space-between",
        boxShadow: isFavourite ? "0px 4px 8px rgba(255, 0, 0, 0.2)" : "none",
      }}
      onClick={handleFavouriteToggle}
      endIcon={isFavourite ? <StarIcon /> : <StarBorderIcon />}
    >
      {isFavourite ? "SAVED" : "SAVE"}
    </Button>
  );
};

export default SaveButton;
