import { Box, Divider, Typography } from "@mui/material";
import EditContentTab from "../EditContentTab";
import EditButton from "../../EditButton";

interface GenresStageProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: { Genres: any; Setting: any };
}

const GenresStage = ({ content }: GenresStageProps) => {
  console.log("GENRE CONTENT", { content });
  const { Genres, Setting } = content;

  // Helper function to handle null or undefined values
  const displayValue = (value: string | null | undefined) =>
    value ? value : "-";

  return (
    <EditContentTab titleText="Genre and Setting">
      {/* Genre Section */}
      <Box
        sx={{
          margin: "8px",
          padding: "16px",
          paddingTop: "0px",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 1,
          position: "relative",
          wordBreak: "break-word",
        }}
      >
        <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
          Genres
        </Typography>
        <Divider
          sx={{
            marginBottom: "10px",
            borderColor: "rgba(255, 255, 255, 0.5)",
          }}
        />

        {/* Display each genre value with null handling */}
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Time Genre:
          </span>{" "}
          {displayValue(Genres.timeGenre)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Structure Genre:
          </span>{" "}
          {displayValue(Genres.structureGenre)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Style Genre:
          </span>{" "}
          {displayValue(Genres.styleGenre?.styleGenre)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Style Genre Medium:
          </span>{" "}
          {displayValue(Genres.styleGenre?.styleGenreMedium)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Reality Genre:
          </span>{" "}
          {displayValue(Genres.realityGenre?.realityGenre)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Reality Sub Genre:
          </span>{" "}
          {displayValue(Genres.realityGenre?.realitySubGenre)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Reality Sub Sub Genre:
          </span>{" "}
          {displayValue(Genres.realityGenre?.realitySubSubGenre)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Internal Content Genre:
          </span>{" "}
          {displayValue(Genres.internalContentGenre?.internalContentGenre)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Internal Content Subgenre:
          </span>{" "}
          {displayValue(Genres.internalContentGenre?.internalContentSubgenre)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            External Content Genre:
          </span>{" "}
          {displayValue(Genres.externalContentGenre?.externalContentGenre)}
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            External Content Subgenre:
          </span>{" "}
          {displayValue(Genres.externalContentGenre?.externalContentSubgenre)}
        </Typography>

        {/* Edit button */}
        <Box sx={{ position: "absolute", top: "13px", right: "0px" }}>
          <EditButton />
        </Box>
      </Box>

      {/* Setting Section */}
      <Box
        sx={{
          margin: "8px",
          padding: "16px",
          paddingTop: "0px",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 1,
          position: "relative",
          wordBreak: "normal",
        }}
      >
        <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
          Setting
        </Typography>
        <Divider
          sx={{
            marginBottom: "10px",
            borderColor: "rgba(255, 255, 255, 0.5)",
          }}
        />

        {/* Display setting values with null handling */}
        <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Setting:
          </span>{" "}
          {displayValue(Setting.setting)}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "rgba(255, 255, 255, 0.7)", marginTop: "10px" }}
        >
          <span
            style={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
          >
            Backdrop:
          </span>{" "}
          {displayValue(Setting.backdrop)}
        </Typography>

        {/* Edit button */}
        <Box sx={{ position: "absolute", top: "13px", right: "0px" }}>
          <EditButton />
        </Box>
      </Box>
    </EditContentTab>
  );
};

export default GenresStage;
