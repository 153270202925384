import { Box, Typography, IconButton } from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";

const CreditsBox = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <DiamondIcon sx={{ color: "white", marginRight: "8px" }} />
      <Typography variant="h6">1250</Typography>
      <IconButton
        sx={{
          color: "white",
          "&:hover": {
            color: "primary.main",
          },
          cursor: "pointer",
          marginLeft: "0px",
        }}
        onClick={() => navigate("/profile")}
      >
        <AddCircleIcon sx={{ fontSize: 18 }} />
      </IconButton>
    </Box>
  );
};

export default CreditsBox;
