import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import { SignedIn, SignedOut, useAuth } from "@clerk/clerk-react";

import Profile from "./pages/Profile";
import Home from "./pages/Home";
import Explore from "./pages/Explore";
import NewStory from "./pages/NewStory";
import StoryPage from "./pages/StoryPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Library from "./pages/Library";
import StoryViewer from "./pages/StoryViewer";
import "./styles/fonts.css";

import Navbar from "./components/navbar/Navbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./styles/theme";

import RedirectToSignIn from "./components/auth/RedirectToSignIn";

function App() {
  const { isSignedIn } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={isSignedIn ? <Navigate to="/explore" /> : <Home />}
          />

          <Route
            path="/"
            element={
              <>
                <SignedIn>
                  <Navbar />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            }
          >
            <Route
              path="profile"
              element={
                <SignedIn>
                  <Profile />
                </SignedIn>
              }
            />
            <Route
              path="explore"
              element={
                <SignedIn>
                  <Explore />
                </SignedIn>
              }
            />
            <Route
              path="library"
              element={
                <SignedIn>
                  <Library />
                </SignedIn>
              }
            />
            <Route
              path="new-story"
              element={
                <SignedIn>
                  <NewStory />
                </SignedIn>
              }
            />
            <Route
              path="story/:storyId"
              element={
                <SignedIn>
                  <StoryPage />
                </SignedIn>
              }
            />
            <Route
              path="story-viewer/:storyId"
              element={
                <SignedIn>
                  <StoryViewer />
                </SignedIn>
              }
            />
          </Route>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
