import React from "react";
import { Box } from "@mui/material";
import StickyHeader from "../header/StickyHeader";

type PageLayoutProps = {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
};

const PageLayout = ({ children, title, subtitle }: PageLayoutProps) => {
  return (
    <Box sx={{ width: "100%", height: "100vh", overflowY: "auto" }}>
      <StickyHeader title={title} subtitle={subtitle} />
      {children}
    </Box>
  );
};

export default PageLayout;
