import { Box, useMediaQuery } from "@mui/material";
import ImageBox from "./ImageBox";

const HomeGallery = () => {
  const isSmallScreen = useMediaQuery("(max-width: 1200px)");

  if (isSmallScreen) {
    return null;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        borderLeft: "1px solid white",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          height: "100%",
          width: "80%",
        }}
      >
        <ImageBox
          imageSrc="images/example-image-1.jpg"
          altText="Black and white picture, short hair woman with glasses, smiling in front of buildings."
          caption="a mystical place where adventurers come to find glory..."
        />
        <ImageBox
          imageSrc="images/example-image-2.jpg"
          altText="Black and white image of a woman with an afro in times square, smiling with hoop earrings."
          caption="“No, you won’t find it here, the artifact is long gone.”"
          reverse
        />
        <ImageBox
          imageSrc="images/example-image-3.jpg"
          altText="Black and white image of a woman, working in an office with a hair bun, glasses and a thoughtful expression."
          caption="she pulls the lever, the elevator creaks, and comes crashing down..."
        />
      </Box>
    </Box>
  );
};

export default HomeGallery;
