import { Button } from "@mui/material";

const DeleteButton = () => {
  return (
    <Button
      fullWidth
      variant="contained"
      sx={{
        background: "linear-gradient(90deg, #f44336 0%, #FF5E57 100%)",
        color: "white",
        padding: "12px",
        fontSize: "16px",
        height: "40px",
        maxWidth: "250px",
        marginBottom: "16px",
        border: "2px solid #FF5E57",
        "&:hover": {
          background: "linear-gradient(90deg, #1E1A1E 0%, #FF5E57 100%)",
          color: "#FF5E57",
        },
      }}
    >
      DELETE ACCOUNT
    </Button>
  );
};

export default DeleteButton;
