import { useState } from "react";
import { Box, Container, Grid } from "@mui/material";

import HomeGallery from "../components/HomeGallery";
import TermsAndPrivacyLinks from "../components/TermsAndPrivacyLinks";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";

const Home = () => {
  const [authType, setAuthType] = useState<"login" | "register">("login");

  return (
    <Container component="main" sx={{ height: "100%", padding: "0px" }}>
      <Box
        component="img"
        src="logos/nib-icon.png"
        alt="Nib Icon"
        sx={{
          position: "absolute",
          top: "16px",
          left: "16px",
          width: "60px",
          height: "auto",
        }}
      />
      <Grid container>
        <Grid
          item
          mobile={12}
          desktop={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          {authType === "login" ? (
            <Login setAuthType={setAuthType} />
          ) : (
            <Register setAuthType={setAuthType} />
          )}

          <TermsAndPrivacyLinks />
        </Grid>
        <Grid item mobile={12} desktop={7}>
          <HomeGallery />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
