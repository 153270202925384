import React from "react";
import { Box } from "@mui/material";
import StepIndicator from "../progressBar/StepIndicator";
import Connector from "../progressBar/Connector";

interface ProgressBarProps {
  currentStep: number;
  stopCircularLoading: boolean;
  steps: string[];
}

const ProgressBar = ({
  currentStep,
  stopCircularLoading,
  steps,
}: ProgressBarProps) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <StepIndicator
            index={index}
            step={step}
            currentStep={currentStep}
            stopCircularLoading={stopCircularLoading}
          />
          {index < steps.length - 1 && (
            <Connector index={index} currentStep={currentStep} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default ProgressBar;
