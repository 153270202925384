import { Alert, AlertColor, AlertPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";

type AlertMessageProps = {
  message: string;
  severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
};

const AlertMessage = ({ message, severity }: AlertMessageProps) => {
  return (
    <Alert variant="filled" severity={severity}>
      {message}
    </Alert>
  );
};

export default AlertMessage;
