import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import ViewerBox from "../components/ViewerBox";
import PreviewBox from "../components/PreviewBox";
import { defaultStories } from "../data/storiesData";
import { getRandomAdjective } from "../utils/adjectives";
import PageLayout from "../components/layout/PageLayout";

const StoryPage = () => {
  const { storyId } = useParams<{ storyId: string }>();

  const [adjective, setAdjective] = useState<string>("interesting");

  useEffect(() => {
    const newAdjective = getRandomAdjective();
    setAdjective(newAdjective);
  }, [storyId]);

  const story = defaultStories.find((s) => s.storyId === storyId);

  if (!story) {
    return <Typography variant="h4">Story not found</Typography>;
  }

  return (
    <PageLayout title="Explore" subtitle={`&quot;${story.title}&quot;`}>
      <Box sx={{ marginTop: "16px", marginLeft: "16px", marginRight: "24px" }}>
        <ViewerBox
          storyId={story.storyId}
          title={story.title}
          bio={story.bio}
          genre={story.genre}
          username={story.username}
          backgroundImage={story.backgroundImage}
        />
        <Box
          sx={{ marginTop: "24px", marginBottom: "16px", textAlign: "center" }}
        >
          <Typography variant="h5" component="h2">
            Other {adjective} {story.genre} Stories
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "0 16px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "16px",
            marginBottom: "64px",
          }}
        >
          {defaultStories
            .filter((s) => s.storyId !== story.storyId)
            .slice(0, 4)
            .map((otherStory) => (
              <PreviewBox
                key={otherStory.storyId}
                storyId={otherStory.storyId}
                title={otherStory.title}
                genre={otherStory.genre}
                username={otherStory.username}
                backgroundImage={otherStory.backgroundImage}
              />
            ))}
        </Box>
      </Box>
    </PageLayout>
  );
};

export default StoryPage;
