import { Box, Typography } from "@mui/material";

type ImageBoxProps = {
  imageSrc: string;
  altText: string;
  caption: string;
  reverse?: boolean;
};

const ImageBox = ({
  imageSrc,
  altText,
  caption,
  reverse = false,
}: ImageBoxProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: reverse ? "row-reverse" : "row",
      }}
    >
      <Box
        component="img"
        src={imageSrc}
        alt={altText}
        sx={{
          width: "18%",
          height: "auto",
          marginLeft: reverse ? "32px" : "16px",
          marginRight: reverse ? "16px" : "32px",
          borderRadius: "4px",
        }}
      />
      <Typography
        variant="body2"
        sx={{ fontSize: "0.8rem", color: "grey.500" }}
      >
        {caption}
      </Typography>
    </Box>
  );
};

export default ImageBox;
