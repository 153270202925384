import { Box } from "@mui/material";
import DeleteButton from "./DeleteButton";
import GreenActionButton from "./GreenActionButton";

const ProfileButtons = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <GreenActionButton buttonText="Public Profile" />
      <GreenActionButton buttonText="My Stories" />
      <DeleteButton />
    </Box>
  );
};

export default ProfileButtons;
