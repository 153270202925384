import { Typography, TextField, Box } from "@mui/material";

interface NonEditableFieldProps {
  fieldValue: string;
  label: string;
}

const NonEditableField = ({ fieldValue, label }: NonEditableFieldProps) => {
  return (
    <Box
      sx={{
        position: "relative",
        marginBottom: "24px",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginBottom: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {label}
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        value={fieldValue}
        disabled
        type={label === "Password" ? "password" : "text"}
        sx={{
          textAlign: "center",
          bgcolor: "rgba(255, 255, 255, 0.05)",
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            "&.Mui-disabled": {
              "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.2)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(255, 255, 255, 0.2)",
              },
              color: "white",
            },
            input: {
              textAlign: "center",
            },
          },
          input: {
            padding: "12px 14px",
          },
        }}
      />
    </Box>
  );
};

export default NonEditableField;
