import { Link, useLocation } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemButton, Box } from "@mui/material";
import { NavItemProps } from "../../utils/navItems";
import { useClerk } from "@clerk/clerk-react";

type NavItemComponentProps = {
  item: NavItemProps;
  isExpanded: boolean;
};

const NavItem = ({ item, isExpanded }: NavItemComponentProps) => {
  const location = useLocation();
  const { signOut } = useClerk();

  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        component={Link}
        onClick={() => item.text === "Logout" && signOut({ redirectUrl: "/" })}
        to={item.path}
        selected={location.pathname === item.path}
        sx={{
          height: "56px",
          justifyContent: isExpanded ? "initial" : "center",
          px: 2.5,
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isExpanded ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <Box sx={{ minWidth: isExpanded ? 80 : 0 }}>
          {isExpanded && item.text}
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
